import KieranRev from '../Assets/KieranReview.png';
import IanRev from '../Assets/IanReview.png';
import ShaneRev from '../Assets/ShaneReview.png';
import StephanRev from '../Assets/StephanReview.png';
import OliverRev from '../Assets/OliverReview.png';
import RogerRev from '../Assets/RogerReview.png';

export const googleReviews = [
    {
        id : 0,
        src : KieranRev,
        name : "Kieran",
        alt : "Kieran Profile Picture",
        desc : "Couldn't ask for a better service. Fixed my issue in no time at all and the PC is now running better than ever. If you want someone who's going to go the extra mile then look no further than here, I'll be sure to use this service again if I have any more PC issues!"
    },
    {
        id : 1,
        src : IanRev,
        name : "Ian Chadderton",
        alt : "Ian Profile Picture",
        desc : "I've had a laptop screen and a battery replaced, excellent service both times. Excellent communication and advice, highly recommend."
    },
    {
        id : 2,
        src : ShaneRev,
        name : "Shane Atkinson",
        alt : "Shane Profile Picture",
        desc : "Chris updated my PC to windows 10 and serviced the tower. Brilliant job done. Chris then sorted out problem with my monitor. I'm so glad that I have found a place that I can trust for all my computer issues. Thank you"
    },
    {
        id : 3,
        src : StephanRev,
        name : "Stephen Owen",
        alt : "Stephan Profile Picture",
        desc : "I have been using CCR Computers for many years now and have used their services again this week. I cannot recommend the company highly enough. Chris is highly knowledgeable, thoroughly professional and has always been able to resolve any IT/Tech issue  I have had, very quickly. Thank you Chris."
    },
    {
        id : 4,
        src : OliverRev,
        name : "Oliver Rose",
        alt : "Oliver Profile Picture",
        desc : "Absolute First Class Service From Chris. I was let down by another company on The Wirral so then came here. I should of come here first,  Turns out it was a RAM issue that the other company completely missed. Chris was brilliant and resolved my laptop issue quickly and efficiently for a very reasonable price. Amazing service and will certainly return again in the future if I have any more computing issues"
    },
    {
        id : 5,
        src : RogerRev,
        name : "Roger Boulton",
        alt : "Roger Profile Picture",
        desc : "Outstanding service. Looked into a problem with my laptop and when found it couldn't be repaired, waived costs involved. On the back of such great customer service, we then retirned to order a gaming PC for my son. Chris went through what we required and provided a system that has delighted my son. Thanks Chris. Will be back soon with some laptops from business that need an overhaul."
    }];
