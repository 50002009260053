import Phone from '../Assets/phone_in_talk.png';
import Location from '../Assets/distance.png';
import Email from '../Assets/Email-icon.png';
export const contactMethods = [
    {
        id : 0,
        src : Location,
        title : "Location",
        fontWeight: "400",
        fontSize :"18px",
        alt : "Location-Map Logo",
        desc : "173 Old Chester Road, Bebington, Wirral, CH63 8NE"
    },
    {
        id : 1,
        src : Email,
        fontWeight: "400",
        fontSize :"18px",
        title : "Email",
        alt : "Email-Contact Logo",
        desc : "enquire@ccrcomputers.co.uk"
    },
    {
        id : 2,
        src : Phone,
        fontWeight: "600",
        fontSize :"34px",
        title : "Phone",
        alt : "Phone-Contact Logo",
        desc : "0151 643 1414"
    }];
