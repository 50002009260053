import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_f2tm884', 'template_kqjqk3q', form.current, 'gbLNBcWkB8GBRE1Zx')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    alert('Thank you for your message! Our Team will be in Contact Shortly.')
    e.target.reset()
  };

  return (
    <div className='form-zone' id='c-form'>
      <h2>Use the form below to send us a message and we’ll be in touch shortly</h2>
      <form ref={form} onSubmit={sendEmail}>

        <input type="text" name="user_name" placeholder='Name' />

        <input type="email" name="user_email" placeholder='Email' />

        <input type="text" name="user_phone" placeholder='Phone' />

        <input type="text" name="user_subject" placeholder='Subject' />

        <textarea name="message" placeholder='Message' />
        <input type="submit" value="Send" />
      </form>
    </div>
  );
};

export default ContactForm;