import React from 'react';
import './Footer.css';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CCRLogo from '../Assets/CCR_LOGO.JPG'

export default function Footer() {
    return (
        <div className="footer">
            <div className="social-media">
                <h4>CCR Computers</h4>

                <div className="social-group">
                    <a href="https://www.facebook.com/profile.php?id=100075770121444" className='social-icons'><FontAwesomeIcon icon={faFacebook} style={{ color: "#f5f5f5", }} /></a>
                </div>
            </div>
            <div className="contact-us">
                <h5>Contact Us</h5>
                <ul>
                    <li><p>173 Old Chester Road, CH63 8NE</p></li>
                    <li><p>Bebington, Wirral, United Kingdom</p></li>
                    <li><a href='tel:01516431414' className="phone-number">0151.643.1414</a></li>
                </ul>
            </div>
            <div className='rights-reserved'>
                <p>@2023 CCRComputers.co.uk | All Rights Reserved</p>
            </div>
        </div>
    )
}