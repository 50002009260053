import React, { useState} from 'react';
import './Card.css';
import { services } from "../Data/services.js";

export default function Card() {
  const [visible, setVisible] = useState(3);
  const [isCompleted, setIsCompleted] = useState(false)


  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 3);

    if (visible >= services.length - 3) {
      setIsCompleted(true)
    } else {
      setIsCompleted(false)
    }
  };

  return (
    <div className='card-zone'>
      {services.slice(0, visible).map(service =>
        <div className='card-shape' key={service.id}>
          <img
            src={service.src}
            alt={service.alt}
            className='card-logo'
          />
          <h2 className='service-title'>{service.title}</h2>
          <ul>
            {service.desc.map(desc =>
              <li key={desc}>
                <p className='card-desc'>{desc}</p>
              </li>
            )}
          </ul>
        </div>
      )}

      <div style={{ maxWidth: 1000, width: '90%', margin: '0px auto'}}>
        {isCompleted ? (
          <button onClick={showMoreItems} style={{display:'none'}}></button>
        ) : (
          <button onClick={showMoreItems} className="get-in-btn">Load More +</button>
        )}
      </div>
    </div>
  );

}

