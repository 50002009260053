import wifi from '../Assets/wifi_van.png';
import laptop from '../Assets/LaptopRepair.png';
import desktop from '../Assets/DesktopRepair.png';
import printer from '../Assets/PrinterRepair.png';
import tablet from '../Assets/SmartphoneRepair.png';
import malware from '../Assets/AntivirusRepair.png';

export const services = [
    {
        id : 0,
        src : laptop,
        title : "Laptop",
        alt : "Laptop-Services Logo",
        desc : ['Screen Repair', 'Hinge Problems', 'Over Heating Issues', 'Keyboard Replacement']
    },
    {
        id : 1,
        src : desktop,
        title : "Desktop",
        alt : "Desktop-Services Logo",
        desc : ['Memory Optimization', 'Storage Upgrades', 'Power Supply Issues', 'Cooling and Fans']
    },
    {
        id : 2,
        src : printer,
        title : "Printers",
        alt : "Printer-Services Logo",
        desc : ['Poor Quality', 'Paper Pickup Issues', 'Paper Jams', 'Authorised Epson Technicians']
    },
    {
        id : 3,
        src : tablet,
        title : "Tablets",
        alt : "Tablet-Services Logo",
        desc : ['Screen Replacements', 'Charger Port Repair', 'Battery Diagnostics', 'Screen Cover Installation']
    },
    {
        id : 4,
        src : malware,
        title : "Virus and Malware",
        alt : "Virus and Malware-Services Logo",
        desc : ['Spyware Detection', 'Antivirus Installation', 'Adware/Pop Up Prevention', 'OS Updates and Upgrades']
    },
    {
        id : 5,
        src : wifi,
        title : "Wireless Internet",
        alt : "Wireless Internet-Services Logo",
        desc : ['Router Installation', 'Wi-Fi Configuration', 'Connectivity Issues', 'Bandwidth Consultation']
    }];

