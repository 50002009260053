import React, { Component, useState } from 'react';
import './ReviewCards.css';
import { googleReviews } from "../Data/googleReviews.js";


export default function ReviewCards() {

    const [visible, setVisible] = useState(3);
    const [isCompleted, setIsCompleted] = useState(false)


    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 3);

        if (visible >= googleReviews.length - 3) {
            setIsCompleted(true)
        } else {
            setIsCompleted(false)
        }
    };

    return (
        <div className='review-zone'>
            {googleReviews.slice(0,visible).map(review =>
                <div className='review-shape' key={review.id}>
                    <img
                        src={review.src}
                        alt={review.alt}
                        className='review-img'
                    />
                    <p className='review-desc'>{review.desc}</p>
                </div>
            )}
            <div style={{ maxWidth: 1000, width: '90%', margin: '0px auto' }}>
                {isCompleted ? (
                    <button onClick={showMoreItems} style={{ display: 'none' }}></button>
                ) : (
                    <button onClick={showMoreItems} className="get-in-btn">Load More +</button>
                )}
            </div>
        </div>
    );
}
