import React from 'react';
import './Navbar.css';
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import CCRLogo from '../Assets/CCR_LOGO.JPG'

import { BsList, BsXCircleFill } from 'react-icons/bs';

export default function Navbar() {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    return (
        <div className='header-bg'>
            <nav className='navigation'>
                <div className='nav-title'>
                    <img className="logo" src={CCRLogo} />
                </div>

                <div className={isNavExpanded ? "nav-list expanded" : "nav-list"}>
                    <BsList className='hamburger' onClick={() => {
                        setIsNavExpanded(!isNavExpanded)
                    }}></BsList>
                    <ul>
                        <li><NavLink className='home-link' to="/" onClick={() => setIsNavExpanded(false)}>Home</NavLink></li>
                        <li><NavLink className='contact-link' to="/contact" onClick={() => setIsNavExpanded(false)}>Contact Us</NavLink></li>
                    </ul>

                    <NavLink to="/contact" onClick={() => setIsNavExpanded(false)}><button className='contact-btn'>Contact Us</button></NavLink>

                    <BsXCircleFill className='close-menu' onClick={() => {
                        setIsNavExpanded(false)
                    }}></BsXCircleFill>
                </div>

            </nav>
        </div>

    );
}