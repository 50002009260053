import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import './ContactUs.css';
import ContactCards from '../Components/ContactCards.js';
import ContactForm from '../Components/ContactForm.js';
import ShopPhoto from '../Assets/ShopPhoto.jpg'

export default function ContactUs() {
    return (
        <div className='contact-details'>
            <h1>Contact Methods</h1>
            <ContactCards />
       
            <ContactForm />
            <img src={ShopPhoto} alt='Photo of Shop' className='shop-photo' ></img>

            <iframe className='shop-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2380.818472916769!2d-3.0179951234600755!3d53.36440357229463!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b27433bcb4771%3A0xabb3e4a1952b05ff!2sCCR%20Computers!5e0!3m2!1sen!2suk!4v1692025527286!5m2!1sen!2suk"
                width="1000"
                height="450"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    );
}