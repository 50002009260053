import React from 'react';
import './App.css';
import Home from './Components/Home.js';
import Contact from './Components/ContactUs.js';
import Footer from './Components/Footer.js';
import Navbar from './Components/Navbar.js';
import { Route, BrowserRouter, Routes } from 'react-router-dom';

export default function App(){
  return (
    <BrowserRouter>
      <div className='App'>
        <Navbar />
        <Routes>
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}
