import React from 'react';
import './ContactCards.css';
import { contactMethods } from "../Data/contactMethods.js";

export default function ContactCards() {
    return (
        <div className='contact-method'>
            {contactMethods.map(contact =>
                <div className='contact-shape' key={contact.id}>
                    <img
                        src={contact.src}
                        alt={contact.alt}
                        className='contact-logo'
                    />
                    <h2 className='contact-title'>{contact.title}</h2>
                    <p className='contact-desc' style={{fontWeight : contact.fontWeight, fontSize : contact.fontSize }}>{contact.desc}</p>
                </div>
            )}
        </div>
    );
}
