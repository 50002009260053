import { HashLink as Link } from 'react-router-hash-link';
import { useEffect } from 'react';
import Cards from '../Components/Card.js';
import ReviewCards from '../Components/ReviewCards.js';

export default function Home() {
    return (
        <div>
            <div className='landing-message'>
                <h1 className='message-statement'>Computer Repair</h1>
                <h1 className='message-statement' style={{fontSize : '26px', fontStyle: 'italic' }}>Serving the Wirral 26 years</h1>               
                <h2 className='message-guarantee' style={{margin: '0px auto'}}>No Fix. No Fee.
                </h2>
            </div>
            <h1>Explore our Services</h1>
          
            <Cards />
            <div className='get-in-touch'>
                <h2>Contact for Other Available Services</h2>
                <h3>And your FREE Estimate!</h3>
                <Link to="/contact#c-form" ><button className='get-in-btn'>Contact Us</button></Link>                
            </div>
            <h2>See What Our Customers Say</h2>
            <ReviewCards />
        </div>
    );
}